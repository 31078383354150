* {
  box-sizing: border-box;
}

body,
html,
.App,
#root,
.outer {
  width: 100%;
  height: 100%;
}
body {
  background-color: #a3a3a308;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.outer {
  padding: 20px;
  margin-top: 40px;
}

.inner {
  max-width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 20px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  border: 1px solid black;
}

.outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.login-container {
  width: 100%;
  height: 100%;
  background: "#fff!important";
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.btngroup {
  display: flex;
}
.btngroup .btn + .btn {
  margin-left: 20px;
}
.btngroup > .button:not(:last-child) {
  margin-right: 10px;
}

.table_main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.table {
  padding: 15px;
}

label {
  float: left;
  font-size: 12px;
}
.header {
  background-color: white;
  padding-top: 20px;

  border-bottom: 1px #707070 solid;
}

.profile {
  border-radius: 50%;
  width: 40px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 110px;
  box-shadow: 1px 2px 6px 1px rgb(0 0 0 / 20%);
  border-radius: 4px;
  z-index: 1;
  font-size: 12px;
  right: 0px;
  padding: 5px 15px;
}

.dropdown-content .user-name {
  color: #000;
  font-weight: 700;
}

.dropdown-content:before {
  border-bottom: 16px solid rgba(0, 0, 0, 0);
  border-left: 16px solid rgba(0, 0, 0, 0.2);
  border-top: 16px solid rgba(0, 0, 0, 0);
  right: -16px;
}

.dropdown-content:after {
  border-bottom: 16px solid rgba(0, 0, 0, 0);
  border-left: 16px solid #ffffff;
  border-top: 16px solid rgba(0, 0, 0, 0);
  right: -15px;
}

.dropdown-content a {
  color: blue;
  padding: 5px 5px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.btn-dark {
  font-size: 14px;
}

.form-btn {
  margin-top: 100px;
  text-align: right;
}
.form-btn .btn + .btn {
  margin-left: 20px;
}
.form-search-btn {
  padding: 20px 0;
}
.form-search-btn .btn + .btn {
  margin-left: 20px;
}
.search-tab-container {
  width: 100%;
  border-bottom: 1px #000 solid !important;
}
.search-btn-row {
  margin-left: 0px;
}
.search-tab-container .nav-item {
  border: 1px #000 solid;
  border-bottom: 0;
  border-right: 0;
  font-size: 12px;
}
.search-tab-container .nav-item:last-child {
  border-right: 1px #000 solid;
}
.form-control {
  font-size: 12px;
  border-color: #707070;
}

.svg {
  display: flex;
  justify-content: space-evenly;
}

p,
strong {
  display: flex;
}

.nav-tabs .nav-item + .nav-item {
  border-left: 1px #000 solid;
}

.nav-tabs .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.76);
  color: #fff;
}

.navs {
  display: flex;
}
.nav-link {
  color: #000;
}
.nav-tabs .nav-link {
  border: 0;
  border-radius: 0;
}
.nav-tabs .nav-link.active {
  background-color: #000;
  color: #fff;
}
.wrapper {
  background-color: white;
  margin-top: 30px;
  border: 1px solid lightgray;
  padding: 20px;
}

.container-header {
  display: flex;
  justify-content: space-between;
}
.container-footer {
  border-top: 1px solid black;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.sub_main {
  padding: 20px 0 0;
}

.wrapper_datatable {
  background-color: white;
  margin-top: 30px;
  border: 1px solid lightgray;
  padding: 20px;
}

.main_header {
  padding: 30px;
  border-bottom: 1px solid currentColor;
}

.page-title {
  margin-bottom: 20px;
}

.nav-tabs {
  color: black;
  border-color: black;
  /* width: 160px;
  padding: 5px; */
}

.nav-container {
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}
.nav-tabs .nav-link {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 12px;
}

.nav-item-border {
  border-right: 1px solid #000;
}

.btn {
  border-radius: 15px;
}

.nav-tabs {
  border-bottom: none;
}

.col-form-label {
  display: flex;
}

.form-check {
  display: flex;
  padding: 5px;
}

.conatiner-outer {
  width: 100%;
  height: 100%;
  background: "#fff";
}

#customers {
  font-family: Graphik, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  padding: 8px;
  text-align: left;
  border-color: rgba(102, 51, 51, 0.157);
  border-width: 1px;
  /* border-style: solid; */
  font-size: 14px;
  /* position: sticky; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

th {
  padding-top: 4px;
  padding-bottom: 6px;
  text-align: left;
  color: rgba(0, 0, 0, 0.774);
  background-color: rgba(204, 204, 204, 0.774);
}

/* .maintable{
      padding:25px;
  } */

.Table {
  border-spacing: 0px;
  background: #fff;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  width: 100%;
}

.table thead th {
  border-top: 0;
  border-bottom: 0;
  background-color: #f3f3f3;
  padding: 0.5rem;
}
.table td,
.table th {
  /* border-bottom: 1px solid #BBBBBB; */
  border-top: 0;
  padding: 0.5rem;
}
.table-row {
  border-bottom: 1px solid #bbbbbb;
}

.table p {
  margin: 0;
  line-height: 1.2;
}
.table p + p {
  margin-top: 5px;
}

.Cell {
  /* border: 1px solid #f4f6f8; */
  /* text-align: center; */
}

.main_table {
  padding: 15px;
  margin: 15px;
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.custom-file-input {
  z-index: 9;
}

.jss114 {
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 3px 1px -2px rgb(0 0 0 / 12%);
}

.jss110 {
  background-color: #fff;
}

.has-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

#flyoutMenu {
  width: 25vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 75px;
  left: 0;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  overflow: scroll;
  z-index: 1000;
  border: 1px solid black;
  padding: 25px;
}

#flyoutMenu.hide {
  transform: translate3d(100vw, 0, 0);
}

#flyoutMenu.show {
  transform: translate3d(75vw, 0, 0);
  overflow: hidden;
}

#flyoutMenu h2 a {
  color: #333;
  margin-left: 15px;
  text-decoration: none;
}

#flyoutMenu h2 a:hover {
  text-decoration: underline;
}

#icon {
  position: absolute;
  top: 5px;
  right: 20px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.page-link {
  color: #707070;
  font-size: 14px;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 100% !important;
}

.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  border-radius: 0rem !important;
}

.dropdown-menu {
  padding: 0rem 0 !important;
}

.jss110 {
  z-index: 1000 !important;
}

@media screen and (max-width: 1440px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px;
  }
}

/* (1680x1050) WSXGA+ Display */
@media screen and (max-width: 1680px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1380px;
  }
}

/* (1920x1080) Full HD Display */
@media screen and (max-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1860px;
  }
}

/* (1600x900) HD+ Display */
@media screen and (max-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1500px;
  }
}
